@import '../../node_modules/@zambezi/sdk-themes/zb-natwest-nonpersonal-standard/theme.min.css';
@import './layout-grid.css';
@import './components.css';
@import '../css/vb-hcd-natwest-min.css';


div#subheading {
  background-color: rgb(245, 245, 245);
  padding-top: 20px;
  padding-bottom: 35px;
}

div#highvol{
  background-color: rgb(245, 245, 245);
  padding-bottom: 150px;
}

:root {
  --bg-color: #42145F;
  --cta-clr: #1D7B8A;
  --h1:18px;
  --h2:22px;
  --brad:0px;
}

.zb-input-is-error {
  border: 1px solid #cf223f;
  background: #fff;
}

/* added by GV*/
.vb-postcode-sec {
  display: none;
}

/*:focus{

  border: 5px solid red !important;
}*/
.html, .body {
  background-color: var(--bg-color);
  min-height: 100%;
  padding: 0;
  margin: 0;
  font-family: RNHouseSansRegular, Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

/* S*/
@media (min-width: 0px) and (max-width: 767px) {
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 5px;
    /* border: 1px solid #42145f; */
    border-radius: 3px;
    width: 90%;
    height: 75%;
    /* position: center; */
    color: #333333;
    font-size: 14px;
    letter-spacing: 0.1px;
    font-weight: normal;
    overflow-x: auto;
    overflow-y: auto;
    /* Enable scroll if needed */
    top: 110px;
    padding-left: 10px;
  }

  .zb-container-vb {
    max-width: 375px;
    width: 100%;
    margin: auto;
    /*padding-left: 93px;  */
  }


  .vb-header {
    height: 60px;
    min-height: 60px;
    width: 100%;
    background: var(--bg-color);
    border-radius: 0px;
  }

  .vb-header-img {
    height: var(--h1);
    /*padding-left:  93px;*/
    padding-top: 19px;
    padding-bottom: 23px;
  }

  .vb-app-content2 {
    color: #333333;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 50px;
  }

  .vb-app-sub-heading {
    height: 32px;
    color: var(--bg-color);
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    margin: 0px;
  }
  .vb-app-sub-heading-input {
    height: 32px;
    color: var(--bg-color);
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    margin: 0px;
    padding-top: 0px;
  }

  .vb-app-content {
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 26px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .vb-atom-image-showhide {
    display: none;
  }

  .vb-atom-image {
    display: inline;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .vb-app-main-heading {
    color: var(--bg-color);
    font-size: 34px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 44px;
    padding-top: 40px;
    padding-bottom: 30px;
    margin: 0;
    display: block;
  }

  .vb-app-main-heading-input1 {
    color: var(--bg-color);
    font-size: 34px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 44px;
    width: 300px;
    padding-top: 40px;
    padding-bottom: 30px;
    margin: 0;
    display: block;
  }
  .vb-app-main-heading-l2 {
    display: none;
  }

  .vb-m-app-main-heading-l2 {
    width: 335px;
    /*height: 132px;*/
    color: var(--bg-color);
    font-size: 34px;
    font-weight: normal;
    letter-spacing: -0.1px;
    line-height: 44px;
    padding-top: 40px;
    margin: 0;
  }

  .vb-app-sub-heading-l2 {
    width: 335px;
    /*height: 32px;*/
    color: rgb(66, 20, 95);
    font-size: 24px;
    /*font-family: RNHouseSans-Regular;*/
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 32px;
  }

  .vb-app-container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    /*overflow-y: scroll;*/
  }

  .vb-bottom-section {
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
  }

  .vb-bottom-section2 {
    padding-top: 0px;
    min-height: 119px;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
  }
  
  .vb-bottom-section3 {
    padding-top: 5px;
    padding-bottom: 25px;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
  }

  .vb-app-cancel {
    padding-top: 20px;
  }

  .vb-footer {
    width: 100vw;
    height: 100px;
    border-radius: 0px;
    text-align: center;
  }

  .vb-footer-line {
    height: 2px;
    background: var(--bg-color);
    border-radius: 0px;
  }

  .vb-footer-text {
    height: 26px;
    color: #333333;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.16px;
    line-height: 26px;
    text-align: center;
    display: inline-block;
    padding-top: 3px;
  }

  .vb-cust-disclaimer {
    width: 290px;
    color: #333333;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 18px;
    padding-bottom: 40px;
    text-justify: distribute-all-lines;
  }

  .vb-app-subheading2 {
    padding-top: 34px;
    color: var(--bg-color);
    font-size: 18px;
    font-weight: normal;
    line-height: 26px;
  }

  .zb-natwest-nonpersonal-standard-theme .vb-cust-input .zb-input {
    width: 335px;
    height: 66px !important;
  }

  .vb-video-help-text {
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 26px;
    width: 328px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .vb-video-frame {
    width: 335px;
    height: 200px;
  }

  .vb-video-accordion-header {
    width: 335px;
    height: 30px;
    color: var(--cta-clr);
    font-size: 16px;
    padding-left: 0px;
    padding-right: 0px;
    font-weight: normal;
    letter-spacing: 0.18px;
    line-height: 30px;
    padding-top: 12px;
    padding-bottom: 25px;
  }

  .vb-video-accordion-content {
    width: 335px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .vb-waiting-page-content {
    width: 335px;
    /*height: 182px;*/
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 26px;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .vb-waiting-bottom-content {
    width: 375px;
    height: 321px;
    background: rgb(245, 245, 245);
    border-radius: 0px;
  }

  .vb-waiting-button {
    width: 240px;
    height: 60px;
    border: 1px solid rgb(29, 123, 138);
    border-radius: 0px;
    color: rgb(29, 123, 138);
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 30px;
  }

  .vb-waiting-page-content-l2 {
    width: 335px;
    height: 104px;
    color: rgb(51, 51, 51);
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 26px;
  }

  .vb-image-content {
    width: 72px;
    height: 72px;
  }

  .vb-image-content2 {
    width: 72px;
    height: 72px;
  }

  .vb-image-content-padding {
    padding-top: 40px;
    padding-left: 0px;
  }

  .vb-image-content-padding1 {
    padding-top: 40px;
    padding-left: 0px;
  }

  .vb-m-highvol {
    display: block;
  }

  .vb-l-highvol {
    display: none;
  }

  .vb-spinner {
    /*width: 72px;
    height:72px;  */
    text-align: center;
    background-color: "white";
    display: inline-block;
  }

  .vb-m-spinner {
    display: block;
    /*padding-top: 40px;*/
  }

  .vb-m-waitspinner {
    display: block;
    /*padding-top: 40px;*/
    padding-left: 120px;
  }

  .vb-l-spinner {
    display: none;
  }

  .vb-l-waitspinner {
    display: none;
  }

  .vb-xl-waitspinner {
    display: none;
  }
}
/* M */
@media (min-width: 768px) and (max-width: 1023px) {
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 5px;
    /* border: 1px solid #42145f; */
    border-radius: 3px;
    width: 45%;
    height: 75%;
    /* position: center; */
    color: #333333;
    font-size: 14px;
    letter-spacing: 0.1px;
    font-weight: normal;
    overflow-x: auto;
    overflow-y: auto;
    /* Enable scroll if needed */
    top: 110px;
    padding-left: 10px;
  }

  .zb-container-vb {
    width: 768px;
    margin: auto;
    margin-left: 8.33333333%;
    /*padding-left: 93px;*/
  }

  .vb-header-img {
    height: var(--h2);
    /*padding-left:  93px;*/
    padding-top: 39px;
    padding-bottom: 39px;
  }

  .vb-header {
    width: 100%;
    height: 100px;
    min-height: 100px;
    background: var(--bg-color);
    border-radius: 0px;
  }

  .vb-app-sub-heading {
    height: 36px;
    color: var(--bg-color);
    font-size: 26px;
    font-weight: normal;
    line-height: 36px;
    margin: 0px;
    /* padding-top: 30px;*/
  }
  .vb-app-sub-heading-input {
    height: 32px;
    color: var(--bg-color);
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    margin: 0px;
    padding-top: 0px;
  }

  .vb-app-content {
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .vb-app-content2 {
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 50px;
  }

  .vb-atom-image-showhide {
    display: none;
  }

  .vb-atom-image {
    display: inline;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .vb-app-main-heading {
    color: var(--bg-color);
    font-size: 34px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 44px;
    padding-top: 50px;
    padding-bottom: 30px;
    margin: 0;
  }

  .vb-app-main-heading-input1 {
    color: var(--bg-color);
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 44px;
    width: 700px;
    padding-top: 50px;
    padding-bottom: 30px;
    margin: 0;
  }
  .vb-app-main-heading-l2 {
    width: 586px;
    padding-top: 50px;
    color: var(--bg-color);
    font-size: 40px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 50px;
    display: block;
    margin: 0;
  }

  .vb-app-sub-heading-l2 {
    width: 608px;
    height: 26px;
    color: rgb(66, 20, 95);
    font-size: 26px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 36px;
  }

  .vb-m-app-main-heading-l2 {
    display: none;
  }

  .vb-app-container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    /* overflow-y: scroll;*/
  }

  .vb-bottom-section {
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
  }

  .vb-bottom-section2 {
    padding-top: 0px;
    min-height: 445px;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
  }
  
  .vb-bottom-section3 {
    padding-top: 5px;
    padding-bottom: 25px;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
  }

  .vb-app-cancel {
    padding-top: 20px;
  }

  .vb-footer {
    width: 100vw;
    height: 100px;
    border-radius: 0px;
    text-align: center;
  }

  .vb-footer-line {
    height: 2px;
    background: var(--bg-color);
    border-radius: 0px;
  }

  .vb-footer-text {
    height: 26px;
    color: #333333;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.16px;
    line-height: 26px;
    text-align: center;
    display: inline-block;
    padding-top: 3px;
  }

  .vb-cust-disclaimer {
    width: 608px;
    color: #333333;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 18px;
    padding-bottom: 41px;
    text-justify: distribute-all-lines;
  }

  .vb-app-subheading2 {
    padding-top: 30px;
    color: var(--bg-color);
    font-size: 22px;
    font-weight: normal;
    line-height: 32px;
  }

  .zb-natwest-nonpersonal-standard-theme .vb-cust-input .zb-input {
    width: 471px;
    height: 66px !important;
  }

  .vb-video-help-text {
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 26px;
    width: 404px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .vb-video-frame {
    width: 403px;
    height: 240px;
  }

  .vb-video-accordion-header {
    width: 403px;
    height: 30px;
    color: var(--cta-clr);
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 12px;
    padding-bottom: 42px;
  }

  .vb-video-accordion-content {
    width: 403px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .vb-waiting-page-content {
    width: 586px;
    /*height: 104px;*/
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 26px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .vb-waiting-bottom-content {
    width: 768px;
    height: 314px;
    background: rgb(245, 245, 245);
    border-radius: 0px;
  }

  .vb-waiting-button {
    width: 240px;
    height: 60px;
    border: 1px solid rgb(29, 123, 138);
    border-radius: 0px;
    color: rgb(29, 123, 138);
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 30px;
  }

  .vb-waiting-page-content-l2 {
    width: 586px;
    height: 78px;
    color: rgb(51, 51, 51);
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 26px;
  }

  .vb-m-highvol {
    display: none;
  }

  .vb-l-highvol {
    display: block;
  }

  .vb-spinner {
    /* width: 96px;
     height:96px;*/
    text-align: center;
    background-color: "white";
    display: inline-block;
  }

  .vb-m-spinner {
    display: block;
    /*padding-top: 50px;*/
  }

  .vb-m-waitspinner {
    display: block;
    /*padding-top: 50px;*/
    padding-left: 240px;
  }

  .vb-l-spinner {
    display: none;
  }

  .vb-l-waitspinner {
    display: none;
  }

  .vb-xl-waitspinner {
    display: none;
  }

  .vb-image-content {
    width: 96px;
    height: 96px;
  }

  .vb-image-content2 {
    width: 96px;
    height: 96px;
  }

  .vb-image-content-padding {
    padding-top: 50px;
    padding-left: 0px;
  }

  .vb-image-content-padding1 {
    padding-top: 50px;
    padding-left: 0px;
  }
}
/* L */
@media (min-width: 1024px) and (max-width: 1255px) {
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 5px;
    /* border: 1px solid #42145f; */
    border-radius: 3px;
    width: 35%;
    height: 75%;
    /* position: center; */
    color: #333333;
    font-size: 14px;
    letter-spacing: 0.1px;
    font-weight: normal;
    overflow-x: auto;
    overflow-y: auto;
    /* Enable scroll if needed */
    top: 110px;
    padding-left: 10px;
  }

  .vb-header-img {
    height: var(--h2);
    /*padding-left:  129px;*/
    padding-top: 39px;
    padding-bottom: 39px;
  }

  .vb-header {
    width: 100%;
    height: 100px;
    min-height: 100px;
    background: var(--bg-color);
    border-radius: 0px;
  }

  .vb-app-container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    /*overflow-y: scroll;*/
  }

  .zb-container-vb {
    width: 1024px;
    margin: auto;
    /* padding-left: 129px; */
  }

  /* added by GV*/
  .vb-postcode-sec {
    display: none;
  }

  .vb-app-main-heading {
    color: var(--bg-color);
    font-size: 42px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 54px;
    padding-top: 50px;
    padding-bottom: 35px;
    margin: 0;
  }
  .vb-app-main-heading-input1 {
    color: var(--bg-color);
    font-size: 42px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 54px;
    padding-top: 50px;
    padding-bottom: 35px;
    margin: 0;
  }

  .vb-app-main-heading-l2 {
    width: 529px;
    height: 108px;
    color: var(--bg-color);
    font-size: 40px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 54px;
    padding-top: 50px;
    display: block;
    margin: 0;
  }

  .vb-app-sub-heading-l2 {
    width: 608px;
    height: 36px;
    color: rgb(66, 20, 95);
    font-size: 26px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 36px;
  }

  .vb-m-app-main-heading-l2 {
    display: none;
  }

  .vb-waiting-page-content {
    width: 530px;
    /*height: 130px;*/
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 26px;
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .vb-waiting-bottom-content {
    width: 1024px;
    height: 288px;
    background: rgb(245, 245, 245);
    border-radius: 0px;
  }

  .vb-waiting-button {
    width: 240px;
    height: 60px;
    border: 1px solid rgb(29, 123, 138);
    border-radius: 0px;
    color: rgb(29, 123, 138);
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 30px;
  }

  .vb-waiting-page-content-l2 {
    width: 608px;
    height: 52px;
    color: rgb(51, 51, 51);
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 26px;
  }

  .vb-app-sub-heading {
    width: 129px;
    height: 36px;
    color: var(--bg-color);
    font-size: 26px;
    font-weight: normal;
    line-height: 36px;
    margin: 0px;
  }
  .vb-app-sub-heading-input {
    height: 32px;
    color: var(--bg-color);
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    margin: 0px;
    padding-top: 0px;
  }

  .vb-app-content {
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .vb-app-content2 {
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 50px;
  }

  .vb-footer-text {
    height: 26px;
    color: #333333;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.16px;
    line-height: 26px;
    text-align: center;
    display: inline-block;
    padding-top: 3px;
  }

  .vb-bottom-section {
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
  }

  .vb-bottom-section2 {
    padding-top: 0px;
    min-height: 312px;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
  }
  
  .vb-bottom-section3 {
    padding-top: 5px;
    padding-bottom: 25px;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
  }

  .vb-app-cancel {
    padding-top: 20px;
  }

  .vb-atom-image-showhide {
    display: block;
  }

  .vb-atom-image {
    display: inline;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .vb-image-content {
    width: 128px;
    height: 128px;
  }

  .vb-image-content2 {
    width: 122px;
    height: 122px;
  }

  .vb-image-content-padding {
    padding-top: 75px;
    padding-left: 0px;
  }

  .vb-image-content-padding1 {
    padding-top: 75px;
    padding-left: 0px;
  }

  .vb-footer {
    width: 100vw;
    height: 100px;
    border-radius: 0px;
    text-align: center;
  }

  .vb-footer-line {
    height: 2px;
    background: var(--bg-color);
    border-radius: 0px;
  }

  .vb-cust-disclaimer {
    width: 681px;
    color: #333333;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 18px;
    padding-bottom: 54px;
    text-justify: distribute-all-lines;
  }

  .vb-app-subheading2 {
    padding-top: 44px;
    color: var(--bg-color);
    font-size: 22px;
    font-weight: normal;
    line-height: 32px;
  }

  .zb-natwest-nonpersonal-standard-theme .vb-cust-input .zb-input {
    width: 471px;
    height: 66px !important;
  }

  .vb-video-help-text {
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 26px;
    width: 506px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .vb-video-frame {
    width: 530px;
    height: 316px;
  }

  .vb-video-accordion-header {
    width: 530px;
    height: 30px;
    color: var(--cta-clr);
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 22px;
    padding-bottom: 28px;
  }

  .vb-video-accordion-content {
    width: 530px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .vb-m-highvol {
    display: none;
  }

  .vb-l-highvol {
    display: block;
  }

  .vb-spinner {
    /*width: 128px;
    height:128px;
    padding-top: 75px;
    padding-left:0px; */
    background-color: "white";
    display: inline-block;
  }

  .vb-m-spinner {
    display: none;
  }

  .vb-m-waitspinner {
    display: none;
  }

  .vb-l-spinner {
    display: block;
  }

  .vb-l-waitspinner {
    display: block;
    padding-left: 200px;
    padding-top: 75px;
  }

  .vb-xl-waitspinner {
    display: none;
  }
}
/* XL */
@media (min-width: 1256px) {
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 5px;
    /* border: 1px solid #42145f; */
    border-radius: 3px;
    width: 35%;
    height: 70%;
    /* position: center; */
    color: #333333;
    font-size: 14px;
    letter-spacing: 0.1px;
    font-weight: normal;
    overflow-x: auto;
    overflow-y: auto;
    /* Enable scroll if needed */
    top: 110px;
    padding-left: 10px;
  }
 
  .vb-header-img {
    height: var(--h2);
    /*padding-left:  144px;*/
    padding-top: 39px;
    padding-bottom: 39px;
  }

  .vb-header {
    width: 100%;
    height: 100px;
    min-height: 100px;
    background: var(--bg-color);
    border-radius: 0px;
  }

  .vb-app-main-heading {
    color: var(--bg-color);
    font-size: 52px;
    font-weight: normal;
    letter-spacing: -0.2px;
    line-height: 64px;
    padding-top: 50px;
    padding-bottom: 35px;
    margin: 0;
  }
  .vb-app-main-heading-input1 {
    color: var(--bg-color);
    font-size: 52px;
    font-weight: normal;
    letter-spacing: -0.2px;
    line-height: 64px;
    padding-top: 50px;
    padding-bottom: 35px;
    margin: 0;
  }

  .vb-app-main-heading-l2 {
    color: var(--bg-color);
    font-size: 42px;
    font-weight: normal;
    letter-spacing: -0.2px;
    line-height: 64px;
    padding-top: 50px;
    width: 830px;
    height: 128px;
    display: block;
    margin: 0;
  }

  .vb-app-sub-heading-l2 {
    width: 608px;
    height: 36px;
    color: rgb(66, 20, 95);
    font-size: 26px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 36px;
  }

  .vb-m-app-main-heading-l2 {
    display: none;
  }

  .vb-waiting-page-content {
    width: 671px;
    /*height: 130px;*/
    color: #333333;
    font-size: 18px;
    padding-top: 39px;
    padding-bottom: 39px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 26px;
  }

  .vb-waiting-bottom-content {
    background-color: rgb(8, 11, 168);
    border-radius: 0px;
  }

  .vb-waiting-button {
    width: 240px;
    height: 60px;
    border: 1px solid rgb(29, 123, 138);
    border-radius: 0px;
    background: rgb(245, 245, 245);
    color: rgb(29, 123, 138);
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 30px;
  }

  .vb-waiting-page-content-l2 {
    width: 700px;
    height: 52px;
    color: rgb(51, 51, 51);
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 26px;
  }

  .vb-app-container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    /*overflow-y: scroll;*/
  }

  .vb-image-content {
    width: 224px;
    height: 224px;
  }

  .vb-image-content2 {
    width: 122px;
    height: 122px;
  }

  .vb-image-content-padding {
    padding-top: 75px;
    padding-left: 0px;
  }

  .vb-image-content-padding1 {
    padding-top: 57px;
    padding-left: 0px;
  }

  .zb-container-vb {
    width: 1256px;
    margin: auto;
    /*padding-left: 144px;*/
  }

  .vb-app-sub-heading {
    width: 129px;
    height: 36px;
    color: var(--bg-color);
    font-size: 26px;
    font-weight: normal;
    line-height: 36px;
    margin: 0px;
  }
  .vb-app-sub-heading-input {
    height: 32px;
    color: var(--bg-color);
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    margin: 0px;
    padding-top: 0px;
  }

  .vb-footer-text {
    height: 26px;
    color: #333333;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.16px;
    line-height: 26px;
    text-align: center;
    display: inline-block;
    padding-top: 3px;
  }

  .vb-bottom-section {
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
  }

  .vb-bottom-section2 {
    padding-top: 0px;
    min-height: 231px;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
  }
  
  .vb-bottom-section3 {
    padding-top: 5px;
    padding-bottom: 25px;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
  }

  .vb-app-cancel {
    padding-top: 20px;
  }

  .vb-atom-image-showhide {
    display: block;
  }

  .vb-atom-image {
    display: inline;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .vb-app-content {
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .vb-app-content2 {
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 50px;
  }

  .vb-footer {
    width: 100vw;
    height: 100px;
    border-radius: 0px;
    text-align: center;
  }

  .vb-footer-line {
    height: 2px;
    background: var(--bg-color);
    border-radius: 0px;
  }

  .vb-cust-disclaimer {
    width: 681px;
    color: #333333;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 18px;
    padding-bottom: 54px;
    text-justify: distribute-all-lines;
  }

  .vb-app-subheading2 {
    padding-top: 44px;
    color: var(--bg-color);
    font-size: 22px;
    font-weight: normal;
    line-height: 32px;
  }

  .zb-natwest-nonpersonal-standard-theme .vb-cust-input .zb-input {
    width: 471px;
    height: 66px !important;
  }

  .vb-video-help-text {
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    width: 506px;
    line-height: 26px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .vb-video-frame {
    width: 530px;
    height: 316px;
  }

  .vb-video-accordion-header {
    width: 530px;
    height: 30px;
    color: var(--cta-clr);
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 30px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 22px;
    padding-bottom: 28px;
  }

  .vb-video-accordion-content {
    width: 530px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .vb-m-highvol {
    display: none;
  }

  .vb-l-highvol {
    display: block;
  }

  .vb-spinner {
    /*width: 224px;
    height:224px;
    padding-top: 75px;
    padding-left:0px;*/
    background-color: "white";
    display: inline-block;
  }

  .vb-m-spinner {
    display: none;
  }

  .vb-m-waitspinner {
    display: none;
  }

  .vb-l-spinner {
    display: block;
  }

  .vb-l-waitspinner {
    display: none;
  }

  .vb-xl-waitspinner {
    display: block;
    padding-left: 300px;
    padding-top: 20px;
  }
}
/* Page -3 */
.vb-input-elt-ht {
  line-height: 66px;
}

/*  End */
/*.zb-control-wrap{
  padding-bottom: 10px;
}*/
/* Next Button */
.zb-button-primary {
  width: 240px;
  border-radius: var(--brad);
}
.vb-button-link{
	color: #fff;
	cursor: pointer;
}

/* Radio Button Right Margin */
.vb-app-content2 .zb-control-radio-group .zb-radio-button {
  margin-right: 18px;
}

.vb-sel-title {
  width: 273px;
}

.vb-cust-input {
  color: #333333;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  /*line-height: 2.5;*/
  margin-bottom: 10px;
}

.vb-cust-input .zb-input-label {
  padding-bottom: 5px;
}

.vb-small-content {
  color: #333333;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 18px;
}

.vb-small-content-padding {
  /*padding-top: 8px;*/
  line-height: 2.5;
}

.vb-input-elt-padding {
  padding-top: 20px;
}

.vb-spinner-container {
  text-align: center;
  position: relative;
}

.vb-accordion .zb-accordion-is-open {
  padding-left: 0px;
  padding-right: 0px;
}

.vb-video-accordion {
  box-shadow: none !important;
  outline: none !important;
}

.vb-video-accordion-header {
  box-shadow: none;
  outline: none;
}

.vb-container-left-margin {
  margin-left: 8.33333333%;
}

.vb-container-no-margin {
  margin-left: 0px;
}

.zb-container-vb .vb-video-accordion-header-text {
  border-bottom: 1px solid;
  padding-bottom: 3px;
  text-decoration: none;
  color: var(--cta-clr);
}

.vb-icon-padding {
  padding-right: 5px;
}

.zb-natwest-nonpersonal-standard-theme .vb-video-section .zb-checkbox {
  margin-right: 10px;
}

.zb-natwest-nonpersonal-standard-theme .zb-container-vb .zb-radio-button-is-checked {
  border-color: var(--bg-color);
  padding: 5px;
  background: var(--bg-color);
}

.zb-natwest-nonpersonal-standard-theme .zb-container-vb .zb-radio-button-circle {
  background-color: white;
}

.zb-natwest-nonpersonal-standard-theme .zb-container-vb zb-radio-button-circle {
  background: var(--bg-color);
}

.zb-natwest-nonpersonal-standard-theme .zb-checkbox-is-checked {
  background: var(--bg-color);
  border-color: var(--bg-color);
}

.vb-privacy-st-2 {
  padding-bottom: 20px;
}

.zb-natwest-nonpersonal-standard-theme .zb-container-vb .vb-video-accordion-content-bg {
  background: whitesmoke;
}

.vb-link-focus {
  border: 2px solid var(--cta-clr);
  padding: 3px;
}

.vb-footer-content {
  padding-top: 13px;
  padding-bottom: 62px;
}

.vb-footer-cookie {
  color: var(--bg-color);
  font-size: 14px;
  font-weight: normal;
  display: inline-flex;
  float: left;
  padding-left: 20px;
  padding-top: 3px;
  line-height: 26px;
}

.zb-natwest-nonpersonal-standard-theme .vb-footer-cookie a {
  text-decoration: none;
  outline: none;
}

.zb-natwest-nonpersonal-standard-theme .vb-footer-cookie a:focus {
  outline: 1px auto #23527c;
}

.zb-natwest-nonpersonal-standard-theme .vb-footer-cookie a:hover {
  color: #23527c;
  cursor: pointer;
  border-bottom: 1px solid #23527c;
  outline: none;
}

.vb-radiobutton-focus {
  border: 2px solid var(--cta-clr);
  padding: 3px;
}

.vb-main-body {
  overflow-x: hidden;
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 75px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.modal-text-container {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  overflow: auto;
  height: 80%;
  max-height: 80%;
  width: 98%;
  background-color: #f7f7f7;
  padding: 2px;
}

.safari-modal-text-container {
  border: 1px solid #2F528F;
  border-radius: 4px;
  overflow: auto;
  min-height: 80%;
  max-height: 90%;
  width: 98%;
  background-color: #ffffff;
  padding: 2px;
}

.safari-popup-text-align{
	 text-align: center;
}

.safari-popup-text-bold{
	font-weight: bold;
}

.safari-popup-hidden{
	display:none;
}

.safari-popup-modal-button {
  padding: 5px 5px;
  background-color: var(--cta-clr);
  color: #fff;
  border: none;
  line-height: 1.5;
  border-spacing: 8px;
  width: 150px;
  height: 45px;
  border-radius: 5px;
  font-size: 13px;
}

.primary-instructions {
  font-size: 14px;
}

.secondary-instructions {
  font-size: 12px;
}

.modal-header {
  color:var(--bg-color);
  font-weight: bold;
  font-size: 22px;
  font-weight: bold;
}

.modal-button {
  padding: 5px 5px;
  background-color: var(--cta-clr);
  color: #fff;
  border: none;
  line-height: 1.5;
  border-spacing: 10px;
  width: 120px;
  height: 45px;
  border-radius: 5px;
  font-size: 13px;
}

.modal-table {
  width: 100%;
  padding-top: 5px;
}

.modal-table tr td {
  text-align: center;
  height: 30px;
  padding: 8px;
}

/*# sourceMappingURL=index.css.map */
